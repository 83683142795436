import {
  RightOutlined,
  FolderOutlined,
  EditOutlined,
  DeleteOutlined,
  TagsOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import FolderForm from "../FolderForm";
import classes from "./CategoryList.module.sass";
import { UserCategoriesDataType } from "../../../api/category";
import { UserFolderDataType } from "../../../api/folder";
type CategoryListProps = {
  categories: UserCategoriesDataType[];
  folders: UserFolderDataType[];
  setFolders: (folders: UserFolderDataType[]) => void;
  selectedCategory: number | null;
  setSelectedCategory: (userCategoryId: number | null) => void;
  isAddingFolder: boolean;
  newFolderName: string;
  setNewFolderName: (name: string) => void;
  handleAddFolder: (userCategoryId: number, folderName: string) => void;
  setIsAddingFolder: (isAdding: boolean) => void;
  onEditCategory: (userCategoryId: number, userCategoryName: string) => void;
  onDeleteCategory: (userCategoryId: number) => void;
  onEditFolder: (
    userCategoryId: number,
    userFolderId: number,
    folderName: string
  ) => void;
  onDeleteFolder: (userFolderId: number, userCategoryId: number) => void;
};

const CategoryList = ({
  categories,
  folders,
  setFolders,
  selectedCategory,
  setSelectedCategory,
  isAddingFolder,
  newFolderName,
  setNewFolderName,
  handleAddFolder,
  setIsAddingFolder,
  onEditCategory,
  onDeleteCategory,
  onEditFolder,
  onDeleteFolder,
}: CategoryListProps) => {
  return (
    <div className={classes.categoryList}>
      {categories.map((category) => (
        <div key={category.user_category_id} className={classes.categoryItem}>
          <div className={classes.categoryItemHeader}>
            <div
              className={classes.categoryItemName}
              onClick={() => {
                setSelectedCategory(
                  selectedCategory === category.user_category_id
                    ? null
                    : category.user_category_id
                );
              }}
            >
              <TagsOutlined className={classes.categoryItemIcon} rev={""} />
              <span className={classes.categoryItemNameText}>
                {category.name}
              </span>
              <RightOutlined
                rev={""}
                className={`${classes.categoryItemIconRight} ${
                  selectedCategory === category.user_category_id
                    ? classes.rotate
                    : ""
                }`}
              />
            </div>
            <div className={classes.categoryItemActions}>
              <Button
                type="text"
                icon={<EditOutlined rev={""} />}
                onClick={() =>
                  onEditCategory(category.user_category_id, category.name)
                }
              />
              <Button
                type="text"
                danger
                icon={<DeleteOutlined rev={""} />}
                onClick={() => onDeleteCategory(category.user_category_id)}
              />
            </div>
          </div>

          {selectedCategory === category.user_category_id && (
            <div className={classes.folderForm}>
              <FolderForm
                isAddingFolder={isAddingFolder}
                newFolderName={newFolderName}
                setNewFolderName={setNewFolderName}
                handleAddFolder={handleAddFolder}
                setIsAddingFolder={setIsAddingFolder}
                categoryId={category.user_category_id}
              />

              <div className={classes.folderList}>
                {folders.map((folder) => (
                  <div
                    key={folder.user_folder_id}
                    className={classes.folderListItem}
                  >
                    <div className={classes.folderListItemName}>
                      <FolderOutlined rev={""} className={classes.folderIcon} />
                      <span className={classes.folderListItemNameText}>
                        {folder.name}
                      </span>
                    </div>
                    <div className={classes.folderListItemActions}>
                      <Button
                        type="text"
                        icon={<EditOutlined rev={""} />}
                        onClick={() =>
                          onEditFolder(
                            category.user_category_id,
                            folder.user_folder_id,
                            folder.name
                          )
                        }
                      />
                      <Button
                        type="text"
                        danger
                        icon={<DeleteOutlined rev={""} />}
                        onClick={() =>
                          onDeleteFolder(
                            folder.user_folder_id,
                            folder.user_category_id
                          )
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default CategoryList;
