import { useEffect, useState } from "react";
import { Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import { INameCardType } from "../../../interface/nameCard";
import classes from "../Footer.module.sass";
import { updateNameCards } from "../../../api";
import {
  IFlexMsgContent,
  useShareFlexMsg,
} from "../../../hooks/useShareFlexMsg";
import { CFlexBubble } from "../../../../flexMessageType";
import { FlexBox, FlexImage } from "@line/bot-sdk/dist/types";
import { useAppStateStore } from "../../../store/appState";
import { hasImageInThisNameCard } from "../../../utils/hasImageInThisNameCard";
import {
  // fullImgModeNameCardAltUrlConfig,
  fullImgModeNameCardButtonLabelConfig,
  fullImgModeNameCardButtonType,
} from "../../../config/fullImgModeNameCardConfig";
import isValidHttpUrl from "../../../utils/isValidHttpUrl";

const UpdateNameCardFullImgModeFooter = () => {
  const { APP, NAMECARD_DATA, USER } = useAppStateStore();
  const { currentConfig } = APP;
  const { accessToken } = USER.userState;
  const { nameCardV3Data, nameCardV3Setting, currentCategory } =
    NAMECARD_DATA.nameCardData;
  const { altText, cardTitle } = nameCardV3Setting;
  const { user_category_id, user_folder_id, category_name, folder_name } =
    currentCategory;

  const navigate = useNavigate();
  const [updateCardID, setUpdateCardID] = useState<number>(0);

  const { sendFlexMsgFromCurrentContent } = useShareFlexMsg();

  const sendMsgContent: {
    _flexMsgContent: IFlexMsgContent;
    _message_text: string;
  } = {
    _flexMsgContent: {
      flexMsgType: { flexMsgType: "fullImgModeFlexMsg" },
      currentContent: nameCardV3Data,
    },
    _message_text: altText,
  };

  useEffect(() => {
    const tempHref = window.location.href;
    const tempHrefArr = tempHref.split("update/");
    const _card_id = Number(tempHrefArr[tempHrefArr.length - 1]);
    setUpdateCardID(_card_id);
  }, []);

  const getImgUrls = (content: CFlexBubble[]) => {
    const imageUrls: string[] = [];
    content.forEach((message) => {
      if (message.body?.contents && message.body.contents.length > 0) {
        if (
          message.body?.contents[0].type === "image" &&
          (message.body?.contents[0] as FlexImage).url.indexOf(
            currentConfig.REACT_APP_IMAGEBASEURL
          ) !== -1
        ) {
          if ((message.body?.contents[0] as FlexImage).url.indexOf("?time")) {
            imageUrls.push(
              (message.body?.contents[0] as FlexImage).url
                .split(currentConfig.REACT_APP_IMAGEBASEURL)[1]
                .split("?time")[0]
            );
          } else {
            imageUrls.push(
              (message.body?.contents[0] as FlexImage).url.split(
                currentConfig.REACT_APP_IMAGEBASEURL
              )[1]
            );
          }
        } else {
          if (
            message.body?.contents[0].type === "image" &&
            message.body?.contents[0].url.indexOf("?time") !== -1
          ) {
            return imageUrls.push(
              message.body?.contents[0].url.split("?time")[0]
            );
          } else {
            if (message.body?.contents[0].type === "image") {
              return imageUrls.push(
                (message.body?.contents[0] as FlexImage).url
              );
            }
          }
        }
      }
    });
    console.log("imageUrls", imageUrls);
    return imageUrls;
  };

  /**
   * 發更新資料庫該cardId名片資料 api
   */
  const UpdateNameCards = async () => {
    const removeS3BaseUrl = (content: CFlexBubble[]) => {
      const temp: CFlexBubble[] = JSON.parse(JSON.stringify(content));
      console.log("temp", temp);
      const result = temp.map((msg) => {
        if (msg.body?.contents && msg.body?.contents.length > 0) {
          if (
            msg.body?.contents[0].type === "image" &&
            msg.body?.contents[0].url.indexOf(
              currentConfig.REACT_APP_IMAGEBASEURL
            ) !== -1
          ) {
            (msg.body?.contents[0] as FlexImage).url = (
              msg.body?.contents[0] as FlexImage
            ).url.split(currentConfig.REACT_APP_IMAGEBASEURL)[1];
            return msg; // 返回修改後的 msg
          } else {
            return msg; // 返回原始的 msg
          }
        } else {
          return msg;
        }
      });
      return result;
    };

    const momileNumberAddTel = (content: CFlexBubble[]): CFlexBubble[] => {
      return content.map((cflexBubble) => {
        if (cflexBubble.body && cflexBubble.body.contents) {
          cflexBubble.body.contents = cflexBubble.body.contents.map(
            (flexComponent) => {
              if (
                flexComponent.type === "box" &&
                flexComponent.action &&
                flexComponent.action.type === "uri" &&
                flexComponent.action.label ===
                  fullImgModeNameCardButtonLabelConfig.phoneButton &&
                flexComponent.contents &&
                flexComponent.contents.length > 0 &&
                flexComponent.contents[0].type === "text" &&
                flexComponent.contents[0].text ===
                  fullImgModeNameCardButtonType.callphoneButton.text
              ) {
                // 只有在 uri 不是以 "tel:+886" 開頭時才添加前綴
                if (!flexComponent.action.uri.startsWith("tel:+886")) {
                  flexComponent.action.uri =
                    "tel:+886" + flexComponent.action.uri;
                }
              }
              return flexComponent;
            }
          );
        }
        return cflexBubble;
      });
    };

    const hasRemoveImageBaseurlCardContent: CFlexBubble[] = removeS3BaseUrl(
      JSON.parse(JSON.stringify(nameCardV3Data))
    );

    const resultNameCardContent: CFlexBubble[] = momileNumberAddTel(
      JSON.parse(JSON.stringify(hasRemoveImageBaseurlCardContent))
    );

    const nameCardContent: INameCardType = {
      card_type: nameCardV3Data.length === 1 ? 1 : 2,
      card_title: cardTitle,
      message_text: altText,
      card_content: JSON.stringify(resultNameCardContent),
      image_urls: getImgUrls(resultNameCardContent),
      user_category_id: user_category_id,
      category_name: category_name,
      user_folder_id: user_folder_id,
      folder_name: folder_name,
    };

    try {
      if (accessToken && updateCardID !== undefined) {
        const { data: res } = await updateNameCards(
          accessToken,
          updateCardID,
          nameCardContent
        );
        if (res) {
          const { data } = res;
          console.log("修改名片成功", data);
        }
      }
    } catch (error) {
      console.log("修改名片失敗", error);
    }
  };

  /**
   * 驗證電子名片
   * @returns boolean  名片是否通過驗證
   */
  const validateNameCard = (): boolean => {
    const errors: JSX.Element[] = [];

    if (!hasImageInThisNameCard(nameCardV3Data)) {
      errors.push(
        <div>尚未上傳底圖圖片，請至少上傳一張圖片在電子名片中。</div>
      );
    }

    if (!cardTitle.trim()) {
      errors.push(<div>電子名片名稱 尚未填寫</div>);
    }

    if (!altText.trim()) {
      errors.push(<div>LINE 訊息通知文字 尚未填寫</div>);
    }

    // 檢查是否為電話按鈕
    const isPhoneButton = (item: FlexBox) => {
      return (
        item.action &&
        item.action.type === "uri" &&
        item.action.label ===
          fullImgModeNameCardButtonLabelConfig.phoneButton &&
        item.contents &&
        item.contents.length > 0 &&
        item.contents[0].type === "text" &&
        item.contents[0].text ===
          fullImgModeNameCardButtonType.callphoneButton.text
      );
    };

    const hasButtonLabelEmpty = () => {
      return nameCardV3Data.some((bubble) =>
        bubble.body?.contents
          .filter((flexComponent) => flexComponent.type !== "image")
          .some(
            (flexButton) =>
              flexButton.type === "box" &&
              flexButton.action &&
              flexButton.action.type === "uri" &&
              !flexButton.action.label.trim()
          )
      );
    };

    const hasNotValidButtonUrl = () => {
      return nameCardV3Data.some((bubble) =>
        bubble.body?.contents
          .filter((flexComponent) => flexComponent.type !== "image")
          .filter(
            (flexComponent) =>
              flexComponent.type === "box" && !isPhoneButton(flexComponent)
          )
          .some(
            (flexButton) =>
              flexButton.type === "box" &&
              flexButton.action &&
              flexButton.action.type === "uri" &&
              !isValidHttpUrl(flexButton.action.uri)
          )
      );
    };

    if (hasButtonLabelEmpty()) {
      errors.push(<div>按鈕文字不能為空，請檢查並修正</div>);
    }

    if (hasNotValidButtonUrl()) {
      errors.push(<div>按鈕連結格式錯誤，請檢查並修正</div>);
    }

    if (errors.length > 0) {
      message.error(<div>{errors}</div>);
      return false;
    }
    return true;
  };

  return (
    <div className={`${classes.buttonItem}  ${classes.navBar}`}>
      <div className={`${classes.buttonItem} ${classes.cancelButton}`}>
        <Button
          type="primary"
          className={`${classes.button} ${classes.buttonGreyBg}`}
          onClick={() => navigate(`/`)}
        >
          <div className={classes.buttonWord}>取消</div>
        </Button>
      </div>

      <div className={classes.buttonItem}>
        <Button
          type="primary"
          className={classes.button}
          onClick={() => {
            if (!validateNameCard()) {
              return;
            }

            accessToken && UpdateNameCards();
            navigate(`/`);
          }}
        >
          <div className={classes.buttonWord}>儲存</div>
        </Button>
      </div>
      <div className={classes.buttonItem + " " + classes.sendButton}>
        <Button
          type="primary"
          className={classes.send}
          onClick={() => {
            if (!validateNameCard()) {
              return;
            }

            sendFlexMsgFromCurrentContent(
              sendMsgContent._flexMsgContent,
              sendMsgContent._message_text
            );
          }}
        >
          <div className={classes.buttonWord}>發送</div>
        </Button>
      </div>
    </div>
  );
};

export default UpdateNameCardFullImgModeFooter;
