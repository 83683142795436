/**
 * 檢查手機號碼是否變更
 * @param mobileNumberInputValue 手機號碼 input 的值
 * @param mobileNumberDatabaseValue 手機號碼資料庫的值
 */
const isMobileNumberChange = (
  mobileNumberInputValue: string | undefined,
  mobileNumberDatabaseValue: string
) => {
  let profileMobileNumberTrim = mobileNumberInputValue?.trim();
  console.log("profileMobileNumberTrim", profileMobileNumberTrim);
  console.log("profileMobileNumber", mobileNumberInputValue);
  console.log("mobile_number", mobileNumberDatabaseValue);
  return (
    // mobileNumberInputValue 代表的是 input 的值，mobileNumberDatabaseValue 代表的是資料庫的值
    // mobileNumberInputValue 只有在符合手機號碼格式的條件下才會把值存到 mobileNumberInputValue 這個狀態中
    // 否則會是 undefined
    // 所以當 mobileNumberInputValue 和 mobileNumberDatabaseValue 不相等時，代表手機號碼有變更
    mobileNumberInputValue &&
    profileMobileNumberTrim !== "" &&
    mobileNumberInputValue !== mobileNumberDatabaseValue
  );
};

export default isMobileNumberChange;
