import React, { useEffect } from "react";
import { Card, Descriptions, Alert, message as AntdMessage } from "antd";
import classes from "./ResultsDisplay.module.sass";
import { userStateType } from "../../../store/appState";

type ResultsDisplayProps = {
  data?: {
    success: boolean;
    message?: string;
    user?: userStateType;
  };
};

const ResultsDisplay = ({ data }: ResultsDisplayProps) => {
  useEffect(() => {
    if (!data) return;

    data.message === "the mobile_number has existed" &&
      AntdMessage.error("手機號碼已經存在了");

    data.message === "the user has existed" &&
      AntdMessage.error("使用者已經存在了");

    data.message === "the mobile_number not existed" &&
      AntdMessage.error("舊資料無此手機號碼");
  }, [data]);

  if (!data) return null;

  if (!data.success) {
    return (
      <Alert
        message="驗證失敗"
        description={
          <div>
            {data.message === "the mobile_number has existed" && (
              <p>手機號碼已經存在了</p>
            )}
            {data.message === "the user has existed" && <p>使用者已經存在了</p>}
            {data.message === "the mobile_number not existed" && (
              <p>舊資料無此手機號碼</p>
            )}
          </div>
        }
        type="error"
        showIcon
        className={classes.alert}
      />
    );
  }

  return (
    <Card className={classes.card}>
      <Descriptions title="使用者資訊" bordered column={1}>
        <Descriptions.Item label="UID">{data.user?.uid}</Descriptions.Item>
        <Descriptions.Item label="Line ID">
          {data?.user?.line_id}
        </Descriptions.Item>
        <Descriptions.Item label="手機號碼">
          {data?.user?.mobile_number}
        </Descriptions.Item>
        <Descriptions.Item label="姓名">
          {data?.user?.realname}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default ResultsDisplay;
