import { Response, axios } from "./index";

type CreateAndUpdateNameCardFoldersType = {
  data: {
    message: string;
    success?: boolean;
  };
};

export type UserFolderDataType = {
  user_folder_id: number;
  name: string;
  brand_id: number;
  type: number;
  folder_idx: null | number;
  user_id: number;
  user_category_id: number;
  category_name: string;
};

type QueryUserFoldersMetaType = {
  current_page: number;
  per_page: number;
  total: number;
};

export type QueryUserFoldersType = {
  data: UserFolderDataType[];
  meta: QueryUserFoldersMetaType;
};

/**
 * 查詢資料夾列表
 * @param accessToken 使用者accessToken
 */
export const queryUserFolders = async (
  accessToken: string
): Promise<Response<QueryUserFoldersType>> => {
  try {
    const { data } = await axios.request<QueryUserFoldersType>({
      method: "GET",
      url: "/api/member/userFolders",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return { data };
  } catch (error: any) {
    console.error("Error querying user folders:", error);
    return { error };
  }
};

export type CreateUserFolderDataType = {
  name: string;
  user_category_id: number;
};

/**
 * 新增資料夾
 * @param accessToken 使用者accessToken
 * @param folderName 資料夾名稱
 * @param userCategoryId 名片類別id
 */
export const createUserFolder = async (
  accessToken: string,
  folderName: string,
  userCategoryId: number
): Promise<Response<CreateAndUpdateNameCardFoldersType>> => {
  try {
    const { data } = await axios.request<CreateAndUpdateNameCardFoldersType>({
      method: "POST",
      url: "/api/member/userFolders",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        name: folderName,
        user_category_id: userCategoryId,
      },
    });
    return { data };
  } catch (error: any) {
    console.error("Error creating name card folder:", error);
    return {
      error,
      data: {
        data: {
          success: false,
          message: error.response.data.message,
        },
      },
    };
  }
};

/**
 * 更新資料夾
 * @param accessToken 使用者accessToken
 * @param userCategoryId 名片類別id
 * @param userFolderId 資料夾id
 * @param folderName 資料夾名稱
 */
export const updateUserFolder = async (
  accessToken: string,
  userCategoryId: number,
  userFolderId: number,
  folderName: string
): Promise<Response<CreateAndUpdateNameCardFoldersType>> => {
  try {
    const { data } = await axios.request<CreateAndUpdateNameCardFoldersType>({
      method: "PATCH",
      url: `/api/member/userFolders/${userFolderId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        user_category_id: userCategoryId,
        name: folderName,
      },
    });
    return { data };
  } catch (error: any) {
    console.error("Error updating name card folder:", error);
    return {
      error,
      data: {
        data: {
          success: false,
          message: error.response.data.message,
        },
      },
    };
  }
};

/**
 * 刪除資料夾
 * @param accessToken 使用者accessToken
 * @param userFolderId 資料夾id
 */
export const deleteUserFolder = async (
  accessToken: string,
  userFolderId: number
): Promise<Response<CreateAndUpdateNameCardFoldersType>> => {
  try {
    const { data } = await axios.request<CreateAndUpdateNameCardFoldersType>({
      method: "DELETE",
      url: `/api/member/userFolders/${userFolderId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return { data };
  } catch (error: any) {
    console.error("Error deleting name card folder:", error);
    return {
      error,
      data: {
        data: {
          success: false,
          message: error.response.data.message,
        },
      },
    };
  }
};

/**
 * 查詢資料夾列表 by 名片類別id
 * @param accessToken 使用者accessToken
 * @param userCategoryId 名片類別id
 */
export const getUserFolderByCategoryId = async (
  accessToken: string,
  userCategoryId: number
): Promise<Response<QueryUserFoldersType>> => {
  try {
    const { data } = await axios.request<QueryUserFoldersType>({
      method: "GET",
      url: `/api/member/userFolders?user_category_id=${userCategoryId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return { data };
  } catch (error: any) {
    console.error("Error getting user folder by category id:", error);
    return { error };
  }
};
