import classes from "./Footer.module.sass";
import CreateNameCardFooter from "./CreateNameCardFooter";
import CreateNameCardFullImgModeFooter from "./CreateNameCardFullImgModeFooter";
import ProfileFooter from "./ProfileFooter";
import UpdateNameCardFooter from "./UpdateNameCardFooter";
import UpdateNameCardFullImgModeFooter from "./UpdateNameCardFullImgModeFooter";
import MemberCheckFooter from "./MemberCheckFooter";
import UserBonusListFooter from "./UserBonusListFooter"; // 24.11.05 SCH ★

import { useAppStateStore } from "../../store/appState";
import UserCouponFooter from "./UserCouponFooter";

const Footer = () => {
  const { PAGEMODE } = useAppStateStore();
  const { currentPageModeState } = PAGEMODE;
  return (
    <footer
      className={
        currentPageModeState === "nameCardsList" ||
        currentPageModeState === "null"
          ? ""
          : classes.root
      }
    >
      <div className={classes.col}>
        {currentPageModeState === "createNameCard" && <CreateNameCardFooter />}
        {currentPageModeState === "createNameCardFullImgMode" && (
          <CreateNameCardFullImgModeFooter />
        )}
        {currentPageModeState === "profile" && <ProfileFooter />}
        {currentPageModeState === "updateNameCard" && <UpdateNameCardFooter />}
        {currentPageModeState === "updateNameCardFullImgMode" && (
          <UpdateNameCardFullImgModeFooter />
        )}
        {currentPageModeState === "userCheck" && <MemberCheckFooter />}
        {currentPageModeState === "userBonus" && <UserBonusListFooter />}
        {currentPageModeState === "userCoupon" && <UserCouponFooter />}
      </div>
      {/* Copyright © 2022 | Elite Soldiers Information. All Rights Reserved */}
    </footer>
  );
};
export default Footer;
