import { Button } from "antd";
import classes from "../Footer.module.sass";
import { useAppStateStore } from "../../../store/appState";
import { useNavigate } from "react-router-dom";

const MemberCheckFooter = () => {
  const { MEMBERCHECK } = useAppStateStore();
  const { isMemberCheck } = MEMBERCHECK;
  const navigate = useNavigate();
  return (
    <div className={`${classes.buttonItem}  ${classes.navBar}`}>
      {isMemberCheck && (
        <div className={classes.buttonItem}>
          <Button
            type="primary"
            className={classes.button}
            onClick={() => navigate("/", { replace: true })}
          >
            <div className={classes.buttonWord}>關閉</div>
          </Button>
        </div>
      )}
    </div>
  );
};

export default MemberCheckFooter;
