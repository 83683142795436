import { CreditCardOutlined } from "@ant-design/icons";
import { Layout, Typography } from "antd";
import classes from "./Header.module.sass";

const { Header: AntHeader } = Layout;
const { Title } = Typography;

const Header = () => {
  return (
    <AntHeader className={classes.header}>
      <div className={classes.headerContent}>
        <div className={classes.headerContentTitle}>
          <CreditCardOutlined
            rev={""}
            className={classes.headerContentTitleIcon}
          />
          <Title level={4} className={classes.headerContentTitleText}>
            名片類別管理
          </Title>
        </div>
      </div>
    </AntHeader>
  );
};

export default Header;
