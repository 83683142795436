import { Response, axios } from "./index";

type CouponCodeResponse = {
  data: {
    success: boolean;
    message: string;
    coupon_code: string;
    coupon_code_id: number;
    coupon_code_skip: string;
    coupon_available: number;
  };
};

type UpdateCouponCodeResponse = {
  data: {
    coupon_code: string;
    data: {
      brand_id: number;
      coupon_batch_id: number;
      coupon_code: string;
      created_at: string;
      discount_num: number;
      discount_type: number;
      id: number;
      mobile_number: string | null;
      name_of_user: string;
      plan_id: number;
      remark_of_user: string | null;
      sent: number;
      updated_at: string;
      used: number | null;
      used_at: string | null;
      user_id: number | null;
      user_id_assign: number | null;
    };
    message: string;
    success: boolean;
  };
};

/**
 * get coupon code api
 * @param accessToken 使用者accessToken
 * @param planID 方案ID
 * @param discountType 折扣類型
 * @param discountRate 折扣率
 * @param couponCodeSkip 優惠券跳過
 */
export const getCouponCode = async (
  accessToken: string,
  planID: number,
  discountType: number,
  discountRate: number,
  couponCodeSkip?: string
): Promise<Response<CouponCodeResponse>> => {
  try {
    const { data } = await axios.request<CouponCodeResponse>({
      url: "/api/member/get-user-coupon",
      data: {
        plan_id: planID,
        discount_type: discountType,
        discount_num: discountRate,
        coupon_code_skip: couponCodeSkip,
      },
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return { data };
  } catch (error: any) {
    return { error: error, data: undefined };
  }
};

/**
 * update coupon code api
 * @param accessToken 使用者accessToken
 * @param couponCode 優惠券代碼
 * @param nameOfUser 使用者名稱
 * @param mobileNumber 使用者手機號碼
 * @param remarkOfUser 使用者備註
 */
export const updateCouponCode = async (
  accessToken: string,
  couponCode: string,
  nameOfUser: string,
  mobileNumber?: string,
  remarkOfUser?: string
): Promise<Response<UpdateCouponCodeResponse>> => {
  try {
    const { data } = await axios.request<UpdateCouponCodeResponse>({
      url: "/api/member/update-user-coupon",
      data: {
        coupon_code: couponCode,
        name_of_user: nameOfUser,
        mobile_number: mobileNumber ?? null,
        remark_of_user: remarkOfUser ?? null,
      },
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return { data };
  } catch (error: any) {
    console.log("error:", error);
    return { error: error, data: undefined };
  }
};
