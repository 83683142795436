import { useState, useEffect } from "react";
import { Card } from "antd";
import classes from "./UserCoupon.module.sass";
import DiscountInput from "./DiscountInput";
import CouponForm from "./CouponForm";
import { useAppStateStore } from "../../store/appState";

const UserCoupon = () => {
  const [couponCode, setCouponCode] = useState<string>("");
  const { PAGEMODE } = useAppStateStore();
  const { setCurrentPageModeState } = PAGEMODE;

  useEffect(() => {
    setCurrentPageModeState("userCoupon");
  }, []);

  return (
    <div className={classes.container}>
      <Card title="優惠券管理系統" className={classes.card}>
        <DiscountInput onCouponReceived={setCouponCode} />
        <CouponForm couponCode={couponCode} />
      </Card>
    </div>
  );
};

export default UserCoupon;
