import { useState, useEffect } from "react";
import { Modal, Input } from "antd";
import classes from "./EditModal.module.sass";

type EditModalProps = {
  isOpen: boolean;
  title: string;
  value: string;
  onClose: () => void;
  onConfirm: (value: string) => void;
};

const EditModal = ({
  isOpen,
  title,
  value,
  onClose,
  onConfirm,
}: EditModalProps) => {
  const [editedValue, setEditedValue] = useState(value);

  useEffect(() => {
    setEditedValue(value);
  }, [value]);

  return (
    <Modal
      className={classes.editModal}
      title={title}
      open={isOpen}
      onCancel={onClose}
      onOk={() => {
        if (editedValue.trim()) {
          onConfirm(editedValue);
          onClose();
        }
      }}
      okText="確認"
      cancelText="取消"
    >
      <Input
        className={classes.editModalInput}
        value={editedValue}
        onChange={(e) => setEditedValue(e.target.value)}
      />
    </Modal>
  );
};

export default EditModal;
