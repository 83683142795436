import React, { useEffect, useState } from "react";
import { Form, Input, Button, Card, message } from "antd";
import { PhoneOutlined, UserOutlined } from "@ant-design/icons";
import classes from "./VerificationForm.module.sass";
import { useAppStateStore } from "../../../store/appState";
import { infotype } from "../index";
import Loading from "../../../components/Loading";

interface VerificationFormProps {
  onSubmit: (values: infotype) => void;
  getAuthCode: (values: infotype) => void;
  getAuthCodeCountdownTime?: number;
  sendAuthCodeLoading: boolean;
  sendAuthCodeCountdownTime: number;
  setSendAuthCodeLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setSendAuthCodeCountdownTime: React.Dispatch<React.SetStateAction<number>>;
}
const VerificationForm = ({
  onSubmit,
  getAuthCode,
  getAuthCodeCountdownTime = 60,
  sendAuthCodeLoading,
  sendAuthCodeCountdownTime,
  setSendAuthCodeLoading,
  setSendAuthCodeCountdownTime,
}: VerificationFormProps) => {
  const [form] = Form.useForm();
  const { USER, APP, BRAND } = useAppStateStore();
  const { userState } = USER;
  const { brandState } = BRAND; // 24.11.30 SCH ★
  const { auth_code_length } = brandState;
  const { isLoading } = APP.appState;

  useEffect(() => {
    form.setFieldsValue({
      realname: userState.realname,
      mobileNumber: userState.mobile_number,
    });
  }, [userState, form]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (sendAuthCodeCountdownTime > 0) {
      timer = setInterval(() => {
        setSendAuthCodeCountdownTime((prev) => prev - 1);
      }, 1000);
    } else {
      setSendAuthCodeLoading(false);
    }
    return () => clearInterval(timer);
  }, [sendAuthCodeCountdownTime]); // 24.12.04 SCH ★

  const handleGetCaptcha = async () => {
    setSendAuthCodeLoading(true);
    setSendAuthCodeCountdownTime(getAuthCodeCountdownTime); // 預設是 60 秒倒數
    getAuthCode(form.getFieldsValue());
  };

  if (isLoading) return <Loading />;

  const handleSubmit = async (values: infotype) => {
    try {
      await onSubmit(values);
    } catch (error: any) {
      console.log("error", error);
      message.error("發生錯誤 請重新輸入");
    }
  };

  return (
    <Card title="使用者驗證" className={classes.card}>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        autoComplete="off"
      >
        <Form.Item
          name="realname"
          label="會員姓名"
          rules={[{ required: true, message: "請輸入姓名" }]}
          hasFeedback
        >
          <div className={classes.inputWrapper}>
            <Input
              prefix={<UserOutlined rev={""} />}
              placeholder="請輸入姓名"
              defaultValue={userState.realname}
              style={{ borderRadius: "0.5rem" }}
            />
          </div>
        </Form.Item>

        <Form.Item
          name="mobileNumber"
          label="手機號碼"
          rules={[
            { required: true, message: "請輸入手機號碼" },
            {
              pattern: /^[0-9]{10}$/,
              message: "請輸入有效的手機號碼",
            },
          ]}
          hasFeedback
        >
          <div className={classes.inputWrapper}>
            <Input
              prefix={<PhoneOutlined rev={""} />}
              placeholder="請輸入手機號碼"
              defaultValue={userState.mobile_number}
              style={{ borderRadius: "0.5rem" }}
            />
          </div>
        </Form.Item>

        <Form.Item
          name="authCode"
          label="驗證碼"
          rules={[
            { required: true, message: "請輸入驗證碼" },
            {
              max: auth_code_length, // 24.12.31 SCH ★
              // eslint-disable-next-line no-useless-concat
              message: "驗證碼最多只能 " + `${auth_code_length}` + " 個字元",
            },
          ]}
        >
          <div className={classes.formItem}>
            <div className={classes.authCodeInput}>
              <Input
                placeholder="請輸入驗證碼"
                onChange={(e) =>
                  form.setFieldsValue({ auth_code: e.target.value })
                }
              />
            </div>

            <div>
              <Button
                onClick={handleGetCaptcha}
                loading={sendAuthCodeLoading}
                disabled={sendAuthCodeCountdownTime > 0}
                className={classes.button}
              >
                {sendAuthCodeCountdownTime > 0
                  ? `${sendAuthCodeCountdownTime} 秒`
                  : "獲取驗證碼"}
              </Button>
            </div>
          </div>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block
            className={classes.button}
          >
            確認送出
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default VerificationForm;
