import { Select, Button, Space, Tooltip, Input } from "antd";
import { FilterOutlined, CloseCircleOutlined } from "@ant-design/icons";
import classes from "./NameCardFilters.module.sass";
import { UserFolderDataType } from "../../../../api/folder";
import { UserCategoriesDataType } from "../../../../api/category";

type Category = {
  id: string;
  name: string;
  folders?: Folder[];
};

type Folder = {
  id: string;
  name: string;
};

type NameCardFiltersProps = {
  categories: UserCategoriesDataType[];
  folders: UserFolderDataType[];
  selectedCategory?: string;
  selectedFolder?: string;
  keyword?: string;
  onKeywordChange: (keyword: string) => void;
  onCategoryChange: (categoryId: string | undefined) => void;
  onFolderChange: (folderId: string | undefined) => void;
  onConfirmFilter: () => void;
  onClearFilters: () => void;
  hasActiveFilters: boolean;
};

const NameCardFilters = ({
  categories,
  folders,
  selectedCategory,
  selectedFolder,
  keyword,
  onKeywordChange,
  onCategoryChange,
  onFolderChange,
  onConfirmFilter,
  onClearFilters,
  hasActiveFilters,
}: NameCardFiltersProps) => {
  return (
    <div className={classes.filters}>
      <Space className={classes.filtersSpace}>
        <div>
          {!selectedCategory && (
            <Input
              style={{ height: "100%" }}
              className={classes.filtersInput}
              placeholder="搜尋關鍵字"
              value={keyword}
              onChange={(e) => onKeywordChange(e.target.value)}
              allowClear
            />
          )}
        </div>
        <div>
          {!keyword && (
            <Select
              style={{ height: "100%" }}
              placeholder="選擇自訂分類"
              className={classes.filtersSelect}
              value={selectedCategory}
              onChange={onCategoryChange}
              allowClear
            >
              {categories.map((category) => (
                <Select.Option
                  key={category.user_category_id}
                  value={category.user_category_id}
                >
                  {category.name}
                </Select.Option>
              ))}
            </Select>
          )}
        </div>
        <div>
          {folders.length > 0 && (
            <Select
              style={{ height: "100%" }}
              placeholder="選擇資料夾"
              className={classes.filtersSelect}
              value={selectedFolder}
              onChange={onFolderChange}
              disabled={!selectedCategory}
              allowClear
            >
              {folders.map((folder) => (
                <Select.Option
                  key={folder.user_folder_id}
                  value={folder.user_folder_id}
                >
                  {folder.name}
                </Select.Option>
              ))}
            </Select>
          )}
        </div>
      </Space>

      <Space className={classes.filtersButtonContainer}>
        <div>
          <Button
            type="primary"
            icon={<FilterOutlined className={classes.filtersIcon} rev={""} />}
            onClick={onConfirmFilter}
          >
            確認篩選
          </Button>
        </div>

        <div>
          {hasActiveFilters && (
            <Tooltip title="清除所有篩選條件">
              <Button
                type="default"
                icon={
                  <CloseCircleOutlined
                    className={classes.filtersIcon}
                    rev={""}
                  />
                }
                onClick={onClearFilters}
              >
                查看全部
              </Button>
            </Tooltip>
          )}
        </div>
      </Space>
    </div>
  );
};

export default NameCardFilters;
