import classes from "./SideBar.module.sass";
import { useEffect, useState, useRef, Fragment } from "react";
import {
  FolderFilled,
  PlusOutlined,
  AppstoreAddOutlined,
} from "@ant-design/icons";
import profileIconSelect from "../../image/icon-profile-select.svg";
import profileIconNomal from "../../image/icon-profile-nomal.svg";
import userBonusIconSelect from "../../image/icon-user-bonus-select.svg"; // 24.11.02 SCH ★
import userBonusIconNomal from "../../image/icon-user-bonus-nomal.svg"; // 24.11.02 SCH ★
import NameCardTypeModal from "./NameCardTypeModal";
import CancelEditModal from "./CancelEditModal";
import { useNavigate } from "react-router-dom";

import {
  pageModeType,
  useAppStateStore,
  nameCardOperationStateType,
} from "../../store/appState";

import { Modal, Form, Input, message } from "antd"; // 24.11.04 SCH ★

import { format } from "date-fns"; // 24.11.04 SCH ★

export interface INameCardType {
  selectNameCardType: "nameCard1.0" | "fullImgMode" | "null";
}

type inputHandelerType = {
  key: "Y4M2" | string; // 24.11.05 SCH
  value: string;
};

const SideBar = () => {
  const { PAGEMODE, NAMECARD_OPERATION } = useAppStateStore();
  const { currentPageModeState, setCurrentPageModeState } = PAGEMODE;
  const { nameCardOperationState, setNameCardOperationState } =
    NAMECARD_OPERATION;
  const { selectNameCardType, action } = nameCardOperationState;
  const [nameCardTypeModalOpen, setNameCardTypeModalOpen] =
    useState<boolean>(false);
  const [cancelEditModalOpen, setCancelEditModalOpen] =
    useState<boolean>(false);
  const [routerPath, setRouterPath] = useState<string>("/");

  const { BRAND, USER, USER_BONUS } = useAppStateStore(); // 24.11.04 SCH
  const { brandState } = BRAND; // 24.11.02 SCH ★
  const { userState } = USER; // 24.11.02 SCH
  const { role, eagle, enable, trial_due_at, mobile_number, name_card_count } =
    userState; // 24.11.05 SCH
  const [trialDueAt, setTrialDueAt] = useState(""); // 24.11.02 SCH ★
  const [isAddBanModalOpen, setIsAddBanModalOpen] = useState(false); // 24.11.05 SCH ★
  const [isEditBanModalOpen, setIsEditBanModalOpen] = useState(false); // 24.11.02 SCH ★
  const [isUserBonusBanModalOpen, setIsUserBonusBanModalOpen] = useState(false); // 24.11.02 SCH ★
  const [isBonusY4M2ModalOpen, setIsBonusY4M2ModalOpen] = useState(false); // 24.11.04 SCH ★
  const { setUserBonusState } = USER_BONUS; // 24.11.04 SCH ★
  const isValidateY4M2 = useRef<boolean>(true); // 24.11.14 SCH ★

  const navigate = useNavigate();

  /**
   * 開啟頁面 Function
   * @param {string} targetPage - 要跳轉的目標頁面
   * @param {string[]} pagesThatRequireModal - 需要顯示取消編輯框的頁面
   * @param {string} action - 目前執行的是跳轉到哪個頁面操作
   **/
  const navigateTo = (
    targetPage: pageModeType,
    pagesThatRequireModal: pageModeType[],
    action: nameCardOperationStateType["action"] | undefined
  ) => {
    if (currentPageModeState === targetPage) return;

    if (pagesThatRequireModal.includes(currentPageModeState)) {
      setCancelEditModalOpen(true);
      setRouterPath(`/${targetPage}`);
      setNameCardOperationState({ action: action ?? "null" });
    } else {
      navigate(`/${targetPage}`);
    }
  };

  /**
   * 開啟名片列表頁面的Function
   **/
  const nameCardOnClickHandler = () => {
    navigateTo(
      "nameCardsList",
      [
        "createNameCard",
        "updateNameCard",
        "createNameCardFullImgMode",
        "updateNameCardFullImgMode",
        "profile",
      ],
      "goNameCardList"
    );
  };

  /**
   * 新增名片按鈕的Function
   * @param {number} nameCardCount - 目前名片數量
   * @param {number} maxAddableNameCards - 此會員可以新增的名片數量
   * @param {number} role - 會員權限
   * @param {number} [allowedRole=2] - 允許的權限
   * @param {boolean} enable - 是否可以新增名片
   * @param {boolean} eagle - 是否是老鷹推廣夥伴  
   * @param {string | null} mobileNumber - 會員手機號碼

   **/
  const handleAddNameCardClick = (
    nameCardCount: number,
    maxAddableNameCards: number,
    role: number,
    allowedRole: number = 2,
    enable: number,
    eagle: number,
    mobileNumber: string | null
  ) => {
    // 目前名片數量大於此會員可以新增的名片數量
    if (nameCardCount >= maxAddableNameCards) {
      setIsEditBanModalOpen(true);
    }
    // 是付費會員或可新增名片權限或老鷹推廣夥伴
    else if (role >= allowedRole || enable || eagle) {
      openNameCardTypeModalHandler();
      setRouterPath("/");
      setNameCardOperationState({ action: "reCreate" });
    } else {
      // 個人資料是否有填手機號碼 // 24.12.08 SCH ★
      if (brandState.trial_name_cards === 0) window.location.reload();
      if (mobileNumber === null || mobileNumber === "") {
        setIsAddBanModalOpen(true);
      } else {
        setIsEditBanModalOpen(true);
      }
    }
  };

  /**
   * 開啟名片類型框的Function
   **/
  const openNameCardTypeModalHandler = () => {
    if (
      [
        "createNameCard",
        "createNameCardFullImgMode",
        "updateNameCard",
        "updateNameCardFullImgMode",
      ].includes(currentPageModeState)
    ) {
      return setCancelEditModalOpen(true);
    }

    setNameCardTypeModalOpen(true);

    if (selectNameCardType === "null") return;
    if (selectNameCardType === "v1.0") {
      navigate("/createNameCard");
    } else if (selectNameCardType === "v3.0") {
      navigate("/createFullImgMode");
    }
  };

  /**
   * 開啟個人資料頁面的Function
   **/
  const profileOnClickHandler = () => {
    navigateTo(
      "profile",
      [
        "createNameCard",
        "updateNameCard",
        "createNameCardFullImgMode",
        "updateNameCardFullImgMode",
      ],
      "goProfile"
    );
  };

  // 24.11.05 SCH ★
  /**
   * 開啟佣金頁面的Function
   **/
  const userBonusOnClickHandler = () => {
    navigateTo(
      "userBonus",
      [
        "profile",
        "createNameCard",
        "createNameCardFullImgMode",
        "updateNameCard",
        "updateNameCardFullImgMode",
      ],
      "goUserBonus"
    );

    if (
      [
        "profile",
        "createNameCard",
        "createNameCardFullImgMode",
        "updateNameCard",
        "updateNameCardFullImgMode",
      ].includes(currentPageModeState)
    ) {
      setUserBonusState({ isUserBonus: true });
    }
  };

  /**
   * 開啟自訂分類頁面的Function
   **/
  const handleCustomCategoriesClick = () => {
    if (role < 2 && !eagle) {
      message.error("您沒有權限使用此功能，可在個人資料頁面升級成付費會員");
      return;
    }
    navigateTo(
      "customCategories",
      [
        "profile",
        "createNameCard",
        "createNameCardFullImgMode",
        "updateNameCard",
        "updateNameCardFullImgMode",
      ],
      "goCustomCategories"
    );
  };

  /**
   * 輸入佣金所屬年月Function
   **/
  const inputHandeler = ({ key, value }: inputHandelerType) => {
    switch (key) {
      case "Y4M2": // 24.11.07 SCH ★
        validateY4M2(value) && setUserBonusState({ y4m2: value });
        console.log("inputHandeler.y4m2 =", value);
        break;

      default:
        break;
    }
  };

  const y4m2_Rule = /^([0-9])*[0-9]+-([0-9])*[0-9]$/; // 24.11.05 SCH ★

  /**
   * 驗證佣金所屬年月Function
   * @param {string} value - 輸入的值
   * @returns {boolean} - 是否驗證成功
   **/
  const validateY4M2 = (value: string) => {
    // slice(2,4) === substring(2,4); (the end byte not taked！)
    let y2 = value.slice(2, 4); // 24.11.05 SCH ★
    console.log("y2 =", y2); // 24.11.05 SCH ★
    if (
      value &&
      value.length === 7 &&
      value[4] === "-" &&
      value.search(y4m2_Rule) !== -1 &&
      value.slice(0, 2) === "20" &&
      y2 >= "23" &&
      y2 <= "99" &&
      value.slice(5) >= "01" &&
      value.slice(5) <= "12"
    ) {
      isValidateY4M2.current = true;
      console.log("isValidateY4M2.current =", true);
      return true; // 24.11.05 SCH ★
    } else {
      if (!value || value.length === 0) {
        isValidateY4M2.current = true;
        console.log("isValidateY4M2.current =", true);
        return true; // 24.11.14 SCH ★
      } else {
        isValidateY4M2.current = false;
        console.log("isValidateY4M2.current =", false);
        return false; // 24.11.05 SCH ★
      }
    }
  };

  // 開啟重新創建名片框的Function
  const reCreateEventModalHandler = () => {
    setNameCardOperationState({ action: "null" });
    setNameCardTypeModalOpen(true);
  };

  // 關閉名片類型框的Function
  const closeNameCardTypeModalHandler = () => {
    setNameCardTypeModalOpen(false);
    setNameCardOperationState({ selectNameCardType: "null" });
  };

  // 關閉取消編輯框的Function
  const closeCancelEditModalHandler = () => {
    setCancelEditModalOpen(false);
    setNameCardOperationState({ selectNameCardType: "null" });
  };

  // 新增名片權益限制提醒的Function
  const AddBanHandleOk = () => {
    setIsAddBanModalOpen(false);
  };

  // 新增名片權益限制提醒的取消按鈕Function
  const AddBanHandleCancel = () => {
    setIsAddBanModalOpen(false);
  };

  // 編輯名片權益限制提醒的確認按鈕Function
  const EditBanHandleOk = () => {
    setIsEditBanModalOpen(false);
  };

  // 編輯名片權益限制提醒的取消按鈕Function
  const EditBanHandleCancel = () => {
    setIsEditBanModalOpen(false);
  };

  // 查詢佣金權益限制提醒的確認按鈕Function
  const UserBonusBanHandleOk = () => {
    setIsUserBonusBanModalOpen(false);
  };

  // 查詢佣金權益限制提醒的取消按鈕Function
  const UserBonusBanHandleCancel = () => {
    setIsUserBonusBanModalOpen(false);
  };

  // 指定佣金所屬年月確認按鈕Function
  const BonusY4M2HandleOk = () => {
    setIsBonusY4M2ModalOpen(false);
    userBonusOnClickHandler(); // 24.11.04 SCH
  };

  // 指定佣金所屬年月取消按鈕Function
  const BonusY4M2HandleCancel = () => {
    setIsBonusY4M2ModalOpen(false);
    setUserBonusState({ y4m2: undefined }); // 24.11.04 SCH
  };

  // 設定預設變數,在 trialDueAt 或 trial_due_at 變更時執行
  useEffect(() => {
    let trialDueAt = trial_due_at?.toString();
    if (trialDueAt) setTrialDueAt(trialDueAt); // 24.11.02 SCH ★
    let now_month = format(new Date(), "yyyy-MM");
    setUserBonusState({ y4m2: now_month }); // 24.11.07 SCH ★
  }, [trialDueAt, trial_due_at, setUserBonusState]);

  // 設定預設選擇的名片類型, 在 selectNameCardType 變更時執行
  useEffect(() => {
    if (selectNameCardType === "null") return;
    if (selectNameCardType === "v1.0") {
      navigate("/createNameCard");
    } else if (selectNameCardType === "v3.0") {
      navigate("/createFullImgMode");
    }
  }, [selectNameCardType]);

  return (
    <Fragment>
      <div
        className={
          (currentPageModeState !== "nameCardsList"
            ? classes.noFoorerHeight
            : classes.noFoorerHeight) +
          " " +
          classes.sideBarContainer
        }
      >
        <ul>
          <div
            onClick={nameCardOnClickHandler}
            className={
              (currentPageModeState === "nameCardsList"
                ? classes.selectColor
                : classes.nomalColor) +
              " " +
              classes.buttonDefault
            }
          >
            <FolderFilled width={32} rev={""} />
          </div>

          <li>
            <div
              onClick={() => {
                handleAddNameCardClick(
                  name_card_count,
                  brandState.trial_name_cards,
                  role,
                  2,
                  enable,
                  eagle,
                  mobile_number
                );
              }}
              className={
                (currentPageModeState === "createNameCard" ||
                currentPageModeState === "createNameCardFullImgMode" ||
                currentPageModeState === "updateNameCard" ||
                currentPageModeState === "updateNameCardFullImgMode"
                  ? classes.selectColor
                  : classes.nomalColor) +
                " " +
                classes.buttonDefault
              }
            >
              <PlusOutlined width={32} rev={""} />
            </div>
          </li>
          <li>
            <div>
              <div
                className={classes.buttonDefault}
                onClick={profileOnClickHandler}
              >
                <img
                  src={
                    currentPageModeState === "profile"
                      ? profileIconSelect
                      : profileIconNomal
                  }
                  alt="profile"
                />
              </div>
            </div>
          </li>
          <li>
            <div>
              <div
                className={classes.buttonDefault}
                onClick={() => {
                  // 當點選自己的頁面不會轉跳
                  if (currentPageModeState === "userBonus") return;
                  if (role >= 2 || eagle) {
                    setIsBonusY4M2ModalOpen(true); // 24.11.04 SCH
                  } else {
                    setIsUserBonusBanModalOpen(true); // 24.11.02 SCH
                  }
                }}
              >
                <img
                  src={
                    currentPageModeState === "userBonus"
                      ? userBonusIconSelect
                      : userBonusIconNomal
                  }
                  alt="userBonus"
                />
              </div>
            </div>
          </li>
          <li>
            <div>
              <div
                className={
                  (currentPageModeState === "customCategories"
                    ? classes.selectColor
                    : classes.nomalColor) +
                  " " +
                  classes.buttonDefault
                }
                onClick={handleCustomCategoriesClick}
              >
                <AppstoreAddOutlined width={32} rev={""} />
              </div>
            </div>
          </li>
        </ul>
        <NameCardTypeModal
          isOpen={nameCardTypeModalOpen}
          closeNameCardTypeModalHandler={closeNameCardTypeModalHandler}
        />
        <CancelEditModal
          isOpen={cancelEditModalOpen}
          routerPath={routerPath}
          closeCancelEditModalHandler={closeCancelEditModalHandler}
          reCreateEventModalHandler={reCreateEventModalHandler}
        />
      </div>

      <Modal
        title="編輯權益限制提醒"
        open={isAddBanModalOpen}
        onOk={AddBanHandleOk}
        onCancel={AddBanHandleCancel}
      >
        <p>無法新增名片，您尚未完成輸入個人資料喔！</p>
      </Modal>

      <Modal
        title="編輯權益限制提醒"
        open={isEditBanModalOpen}
        onOk={EditBanHandleOk}
        onCancel={EditBanHandleCancel}
      >
        <p>
          無法新增名片，您現持有張數已滿免費試用數（
          {`${brandState.trial_name_cards}`}）喔！
        </p>
      </Modal>

      <Modal
        title="查詢權益限制提醒"
        open={isUserBonusBanModalOpen}
        onOk={UserBonusBanHandleOk}
        onCancel={UserBonusBanHandleCancel}
      >
        <p>
          您是一般會員，無聯盟行銷推廣佣金資料喔！您可在〔個人資料〕的頁面看到〈付費解鎖進階功能〉的按鈕。
        </p>
      </Modal>

      <Modal
        title="指定佣金所屬年月"
        open={isBonusY4M2ModalOpen}
        onOk={BonusY4M2HandleOk}
        onCancel={BonusY4M2HandleCancel}
      >
        <div>
          <Form.Item
            label={<span>佣金所屬年月：</span>}
            validateStatus={isValidateY4M2.current ? "success" : "error"}
            hasFeedback
          >
            <Input
              type="text"
              name="Y4M2"
              allowClear
              placeholder="請輸入佣金所屬年月：yyyy-mm"
              defaultValue={format(new Date(), "yyyy-MM")}
              onChange={(e) => {
                inputHandeler({ key: e.target.name, value: e.target.value });
              }}
            ></Input>
            <p></p>
            <p>清空上列欄位內容，則顯示全部資料。</p>
          </Form.Item>
        </div>
      </Modal>
    </Fragment>
  );
};
export default SideBar;
