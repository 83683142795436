import { useEffect, useState } from "react";
import { Button, ConfigProvider, message } from "antd";
import { useNavigate } from "react-router-dom";

import classes from "../Footer.module.sass";
import { INameCardType } from "../../../interface/nameCard";

import { updateNameCards } from "../../../api";
import {
  IFlexMsgContent,
  useShareFlexMsg,
} from "../../../hooks/useShareFlexMsg";
import { nameCardV1DataType, useAppStateStore } from "../../../store/appState";
import { useNameCardFromValidate } from "../../../context/NameCardFromValidate";

const UpdateNameCardFooter = () => {
  const { USER, NAMECARD_DATA } = useAppStateStore();
  const { accessToken } = USER.userState;
  const { nameCardData } = NAMECARD_DATA;
  const { nameCardV1Data, nameCardV1Setting, currentCategory } = nameCardData;
  const { hasValidateButton, setHasValidateButton } = useNameCardFromValidate();

  const navigate = useNavigate();
  const [updateCardID, setUpdateCardID] = useState<number>(0);
  const { sendFlexMsgFromCurrentContent } = useShareFlexMsg();

  const sendMsgContent: {
    _flexMsgContent: IFlexMsgContent;
    _messageText: string;
  } = {
    _flexMsgContent: {
      flexMsgType: { flexMsgType: "V1.0FlexMsg" },
      currentContent: {
        flexMsg: nameCardV1Data,
        flexMsgSetting: nameCardV1Setting,
      },
    },
    _messageText: nameCardV1Setting.altText,
  };

  useEffect(() => {
    const tempHref = window.location.href;
    const tempHrefArr = tempHref.split("update/");
    const _card_id = Number(tempHrefArr[tempHrefArr.length - 1]);
    setUpdateCardID(_card_id);
  }, []);

  const getImgUrls = (content: nameCardV1DataType[] | null) => {
    if (!content) return;
    const imageUrls: string[] = [];
    content.forEach((message) => {
      message.imageUrl.forEach((image) => {
        if (image.imageUrl.includes("brand/name_cards/")) {
          imageUrls.push(image.imageUrl);
        }
      });
    });
    return imageUrls;
  };

  /**
   * 發更新資料庫該cardId名片資料 api
   */
  const UpdateNameCards = async () => {
    const _cardContent = {
      nameCardV1Setting: nameCardV1Setting,
      nameCardV1Data: nameCardV1Data,
    };

    const nameCardContent: INameCardType = {
      card_type: nameCardV1Data && nameCardV1Data.length === 1 ? 1 : 2,
      card_title: nameCardV1Setting.cardTitle!,
      message_text: nameCardV1Setting.altText ?? "電子名片訊息",
      card_content: JSON.stringify(_cardContent),
      image_urls: getImgUrls(nameCardV1Data)!,
      user_category_id: currentCategory.user_category_id,
      category_name: currentCategory.category_name,
      user_folder_id: currentCategory.user_folder_id,
      folder_name: currentCategory.folder_name,
    };

    try {
      if (accessToken && updateCardID !== undefined) {
        const { data: res } = await updateNameCards(
          accessToken,
          updateCardID,
          nameCardContent
        );
        if (res) {
          const { data } = res;
          console.log("修改名片成功", data);
        }
      }
    } catch (error) {
      console.log("修改名片失敗", error);
    }
  };

  const isAllValidationsPassed = (buttonValidate: boolean[]) => {
    return buttonValidate.every((result) => result === true);
  };

  const validateNameCard = (): boolean => {
    const errors: JSX.Element[] = [];

    // 檢查按鈕文字是否為空值
    const hasButtonLabelEmpty = nameCardV1Data?.some((item) =>
      item.button.some((button) => {
        return !button.content.buttonText.trim();
      })
    );

    if (!nameCardV1Setting.cardTitle.trim()) {
      errors.push(<div>電子名片名稱 尚未填寫</div>);
    }
    if (!nameCardV1Setting.altText.trim()) {
      errors.push(<div>LINE 訊息通知文字 尚未填寫</div>);
    }
    if (!isAllValidationsPassed(hasValidateButton)) {
      errors.push(
        <div>
          部分電子名片按鈕（例如：電話、網站連結）尚未通過驗證，請檢查並修正無效的按鈕。
        </div>
      );
    }

    if (hasButtonLabelEmpty) {
      errors.push(<div>按鈕文字不能為空，請檢查並修正</div>);
    }

    if (errors.length > 0) {
      message.error(<div>{errors}</div>);
      return false;
    }
    return true;
  };

  return (
    <div className={`${classes.buttonItem}  ${classes.navBar}`}>
      <div className={`${classes.buttonItem} ${classes.cancelButton}`}>
        <Button
          type="primary"
          className={`${classes.button} ${classes.buttonGreyBg}`}
          onClick={() => {
            setHasValidateButton([]);
            navigate(`/`);
          }}
        >
          <div className={classes.buttonWord}>取消</div>
        </Button>
      </div>
      <div className={classes.buttonItem}>
        <Button
          type="primary"
          className={classes.button}
          onClick={() => {
            if (!validateNameCard()) {
              return;
            }
            accessToken && UpdateNameCards();
            navigate(`/`);
          }}
        >
          <div className={classes.buttonWord}>儲存</div>
        </Button>
      </div>
      <div className={`${classes.buttonItem} ${classes.sendButton}`}>
        <Button
          type="primary"
          className={classes.send}
          // disabled={!isAllValidationsPassed(hasValidateButton)}
          onClick={() => {
            if (!validateNameCard()) {
              return;
            }
            sendFlexMsgFromCurrentContent(
              sendMsgContent._flexMsgContent,
              sendMsgContent._messageText
            );
          }}
        >
          <div className={classes.buttonWord}>發送</div>
        </Button>
      </div>
    </div>
  );
};

export default UpdateNameCardFooter;
