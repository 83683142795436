import React, { useState } from "react";
import { Input, Button, message, Form } from "antd";
import { getCouponCode } from "../../../api/coupon";
import { useAppStateStore } from "../../../store/appState";
import classes from "./DiscountInput.module.sass";

interface DiscountInputProps {
  onCouponReceived: React.Dispatch<React.SetStateAction<string>>;
}

const DiscountInput = ({ onCouponReceived }: DiscountInputProps) => {
  const [discountRate, setDiscountRate] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const { USER } = useAppStateStore();
  const { accessToken } = USER.userState;
  const handleGetCoupon = async () => {
    console.log("discountRate", discountRate);
    if (discountRate === null || isNaN(Number(discountRate))) {
      message.error("請輸入有效的折扣率");
      return;
    }

    setLoading(true);
    try {
      if (!accessToken) return;
      const { data } = await getCouponCode(accessToken, 1, 1, discountRate);
      if (data) {
        const { data: res } = data;
        if (res && res.success) {
          const { coupon_code } = res;
          console.log(coupon_code);
          onCouponReceived(coupon_code);
          return message.success("成功獲取折扣碼");
        } else {
          return message.error("獲取折扣碼失敗");
        }
      }
    } catch (error) {
      return message.error("獲取折扣碼失敗");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form layout="vertical" onFinish={handleGetCoupon}>
      <Form.Item
        required={true}
        label="優惠折扣率"
        className={classes.formItem}
      >
        <div className={classes.inputContainer}>
          <Input
            className={classes.input}
            placeholder="請輸入優惠折扣率"
            value={discountRate ?? undefined}
            onChange={(e) => setDiscountRate(Number(e.target.value))}
            type="number"
            min="0"
            step="0.1"
            max="1"
          />
          <Button
            className={classes.button}
            type="primary"
            loading={loading}
            htmlType="submit"
          >
            取得優惠碼
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default DiscountInput;
