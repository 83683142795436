import { PlusOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import classes from "./CategoryForm.module.sass";

type CategoryFormProps = {
  isAddingCategory: boolean; // 是否正在新增類別的狀態
  newCategoryName: string; // 新類別名稱
  setNewCategoryName: (name: string) => void; // 設定新類別名稱
  handleAddCategory: () => void; // 新增類別 function
  setIsAddingCategory: (isAdding: boolean) => void; // 設定是否是在新增類別的狀態
};

const CategoryForm = ({
  isAddingCategory,
  newCategoryName,
  setNewCategoryName,
  handleAddCategory,
  setIsAddingCategory,
}: CategoryFormProps) => {
  if (!isAddingCategory) {
    return (
      <div className={classes.categoryFormButtonContainerItem}>
        <Button
          type="primary"
          icon={<PlusOutlined rev={""} />}
          onClick={() => setIsAddingCategory(true)}
        >
          新增類別
        </Button>
      </div>
    );
  }

  return (
    <div className={classes.categoryForm}>
      <Input
        value={newCategoryName}
        onChange={(e) => setNewCategoryName(e.target.value)}
        placeholder="輸入類別名稱"
      />
      <div className={classes.categoryFormButtonContainer}>
        <div className={classes.categoryFormButtonContainerItem}>
          <Button type="primary" onClick={handleAddCategory}>
            確認
          </Button>
        </div>
        <div className={classes.categoryFormButtonContainerItem}>
          <Button onClick={() => setIsAddingCategory(false)}>取消</Button>
        </div>
      </div>
    </div>
  );
};

export default CategoryForm;
