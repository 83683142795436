import { useEffect } from "react";
import { Form, Input, Button, message } from "antd";
import classes from "./CouponForm.module.sass";
import { updateCouponCode } from "../../../api/coupon";
import { useAppStateStore } from "../../../store/appState";
import { useShareFlexMsg } from "../../../hooks/useShareFlexMsg";

type CouponFormProps = {
  couponCode: string;
};

type CouponFormData = {
  coupon_code: string;
  name_of_user: string;
  mobile_number: string;
  remark_of_user: string;
};

const CouponForm = ({ couponCode }: CouponFormProps) => {
  const [form] = Form.useForm();
  const { USER, APP } = useAppStateStore();
  const { accessToken, mobile_number, promo_slug, promo_hash } = USER.userState;
  const { currentConfig } = APP;
  const { sendFlexMsgFromCurrentContent } = useShareFlexMsg();

  const flexMsg = {
    id: "newTab-1",
    IntroText: [
      { _key: "newTab-1", id: 0, inputValue: "【工欲善其事，必先利其器】" },
      { _key: "newTab-1", id: 1, inputValue: "【利器在手】：【如虎添翼】" },
    ],
    imageUrl: [
      {
        _key: "newTab-1",
        imageUrl: `${currentConfig.REACT_APP_IMAGEPATH}${currentConfig.REACT_APP_COUPON_IMAGE}`,
      },
    ],
    button: [
      {
        _key: "newTab-1",
        id: 0,
        content: {
          buttonText: "免費試用",
          buttonUrl: `https://liff.line.me/${currentConfig.REACT_APP_LIFFID}?couponCode=${couponCode}${promo_slug ? `&ps=${promo_slug}` : `&ph=${promo_hash}`}`,
          id: 0,
          _key: "newTab-1",
        },
        type: "urlButton",
      },
      {
        _key: "newTab-1",
        id: 1,
        content: {
          buttonText: "電話聯繫",
          buttonUrl: `tel:+886${mobile_number}`,
          id: 1,
          _key: "newTab-1",
        },
        type: "urlButton",
      },
    ],
    title: `您的優惠碼：${couponCode}`,
    titleColor: "#000000",
    introTextColor: "#000000",
    cardBackgroundColor: "#ffffff",
  };

  const onFinish = async (values: CouponFormData) => {
    try {
      if (!accessToken || !values.coupon_code) return;

      const { data } = await updateCouponCode(
        accessToken,
        values.coupon_code,
        values.name_of_user,
        values.mobile_number,
        values.remark_of_user
      );
      if (data) {
        const { data: res } = data;
        if (res && res.success) {
          const { data: CouponCodeDetail } = res;
          message.success("優惠券派送成功！", 5); // 24.12.04 SCH
          console.log("CouponCodeDetail", CouponCodeDetail);
          console.log(
            `${currentConfig.REACT_APP_IMAGEBASEURL}${currentConfig.REACT_APP_IMAGEPATH}${currentConfig.REACT_APP_COUPON_IMAGE}`
          );
          sendFlexMsgFromCurrentContent(
            {
              flexMsgType: { flexMsgType: "V1.0FlexMsg" },
              currentContent: {
                flexMsg: [JSON.parse(JSON.stringify(flexMsg))],
                flexMsgSetting: {
                  altText: "優惠碼派送",
                  cardTitle: `您的優惠碼：${couponCode}`,
                  titleFontSize: "xl",
                  describeFontSize: "lg",
                },
              },
            },
            "優惠碼派送"
          );
          return form.resetFields();
        } else {
          return message.error("派送失敗，請稍後再試");
        }
      }
    } catch (error) {
      return message.error("派送失敗，請稍後再試");
    }
  };

  useEffect(() => {
    if (couponCode) {
      form.setFieldValue("coupon_code", couponCode);
    }
  }, [couponCode, form]);

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Form.Item
        label="折扣優惠碼"
        name="coupon_code"
        rules={[{ required: true, message: "請輸入折扣優惠碼" }]}
      >
        <Input readOnly className={classes.input} value={couponCode} />
      </Form.Item>
      <Form.Item
        label="受贈者姓名"
        name="name_of_user"
        rules={[
          {
            required: true,
            pattern: /^[a-zA-Z\u4E00-\u9FA5\d]+$/,
            message: "請輸入受贈者姓名",
          },
        ]}
      >
        <Input className={classes.input} />
      </Form.Item>

      <Form.Item
        label="受贈者手機號碼"
        name="mobile_number"
        rules={[{ pattern: /^09\d{8}$/, message: "請輸入有效的手機號碼" }]}
      >
        <Input className={classes.input} />
      </Form.Item>

      <Form.Item label="備註或說明" name="remark_of_user">
        <Input className={classes.input} />
      </Form.Item>

      <Form.Item>
        <Button
          className={classes.button}
          type="primary"
          htmlType="submit"
          block
        >
          執行派送
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CouponForm;
