import { FolderAddOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import classes from "./FolderForm.module.sass";
interface FolderFormProps {
  isAddingFolder: boolean;
  newFolderName: string;
  setNewFolderName: (name: string) => void;
  handleAddFolder: (userCategoryId: number, folderName: string) => void;
  setIsAddingFolder: (isAdding: boolean) => void;
  categoryId: number;
}

const FolderForm = ({
  isAddingFolder,
  newFolderName,
  setNewFolderName,
  handleAddFolder,
  setIsAddingFolder,
  categoryId,
}: FolderFormProps) => {
  if (!isAddingFolder) {
    return (
      <div className={classes.folderFormButtonContainerItem}>
        <Button
          icon={<FolderAddOutlined rev={""} />}
          onClick={() => setIsAddingFolder(true)}
          className="mb-3"
        >
          新增資料夾
        </Button>
      </div>
    );
  }

  return (
    <div className={classes.folderForm}>
      <Input
        value={newFolderName}
        onChange={(e) => setNewFolderName(e.target.value)}
        placeholder="輸入資料夾名稱"
      />
      <div className={classes.folderFormButtonContainer}>
        <div className={classes.folderFormButtonContainerItem}>
          <Button
            type="primary"
            onClick={() => handleAddFolder(categoryId, newFolderName)}
          >
            確認
          </Button>
        </div>
        <div className={classes.folderFormButtonContainerItem}>
          <Button onClick={() => setIsAddingFolder(false)}>取消</Button>
        </div>
      </div>
    </div>
  );
};

export default FolderForm;
