import { Response, axios } from "./index";

type CreateAndUpdateNameCardCategoriesType = {
  data: {
    message: string;
    success?: boolean;
  };
};

export type UserCategoriesDataType = {
  user_category_id: number;
  name: string;
  brand_id: number;
  type: number;
  category_idx: null | number;
  user_id: number;
};

type QueryUserCategoriesMetaType = {
  current_page: number;
  per_page: number;
  total: number;
};

type QueryUserCategoriesType = {
  data: UserCategoriesDataType[];
  meta: QueryUserCategoriesMetaType;
};

/**
 * 註冊api
 * @param accessToken 使用者accessToken
 * @param categoryName 名片類別名稱
 */
export const createNameCardCategories = async (
  accessToken: string,
  categoryName: string
): Promise<Response<CreateAndUpdateNameCardCategoriesType>> => {
  try {
    const { data } = await axios.request<CreateAndUpdateNameCardCategoriesType>(
      {
        url: "/api/member/userCategories",
        method: "POST",
        data: {
          name: categoryName,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return { data };
  } catch (error: any) {
    console.error("Error creating name card category:", error); // 更詳細的錯誤訊息
    return {
      error,
      data: {
        data: {
          success: false,
          message: error.response.data.message,
        },
      },
    };
  }
};

/**
 * 查詢名片類別
 * @param accessToken 使用者accessToken
 */
export const queryUserCategories = async (
  accessToken: string
): Promise<Response<QueryUserCategoriesType>> => {
  try {
    const { data } = await axios.request<QueryUserCategoriesType>({
      url: "/api/member/userCategories",
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return { data };
  } catch (error: any) {
    console.error("Error querying user categories:", error);
    return { error };
  }
};

/**
 * 查詢名片類別
 * @param accessToken 使用者accessToken
 * @param userCategoryId 名片類別id
 */
export const queryUserCategoriesById = async (
  accessToken: string,
  userCategoryId: number
): Promise<Response<UserCategoriesDataType>> => {
  try {
    const { data } = await axios.request<UserCategoriesDataType>({
      url: `/api/member/userCategories/${userCategoryId}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return { data };
  } catch (error: any) {
    console.error("Error querying user categories:", error);
    return { error };
  }
};

/**
 * 更新名片類別
 * @param accessToken 使用者accessToken
 * @param userCategoryId 名片類別id
 * @param userCategoryName 名片類別名稱
 */
export const updateUserCategory = async (
  accessToken: string,
  userCategoryId: number,
  userCategoryName: string
): Promise<Response<CreateAndUpdateNameCardCategoriesType>> => {
  try {
    const { data } = await axios.request<CreateAndUpdateNameCardCategoriesType>(
      {
        url: `/api/member/userCategories/${userCategoryId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        method: "PATCH",
        data: {
          name: userCategoryName,
        },
      }
    );
    return { data };
  } catch (error: any) {
    console.error("Error updating name card category:", error);
    return {
      error,
      data: {
        data: {
          success: false,
          message: error.response.data.message,
        },
      },
    };
  }
};

/**
 * 刪除名片類別
 * @param accessToken 使用者accessToken
 * @param userCategoryId 名片類別id
 */
export const deleteUserCategory = async (
  accessToken: string,
  userCategoryId: number
): Promise<Response<CreateAndUpdateNameCardCategoriesType>> => {
  try {
    const { data } = await axios.request<CreateAndUpdateNameCardCategoriesType>(
      {
        url: `/api/member/userCategories/${userCategoryId}`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return { data };
  } catch (error: any) {
    console.error("Error deleting name card category:", error);
    return { error };
  }
};
