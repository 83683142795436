import shareLogo from "../../../../image/back-icon.svg";
import editLogo from "../../../../image/edit-icon.svg";
import deleteLogo from "../../../../image/icon-delete.svg";
import defaultCoverImg from "../../../../image/default-cover.svg";

import classes from "./NameCardItem.module.sass";

const timeFormat = (_originTime: string) => {
  const temp = _originTime.split("T");
  const date = temp[0];
  const time = () => {
    const timeArr = temp[1].split("+")[0].split(":");
    return timeArr[0] + ":" + timeArr[1];
  };
  const result = date + " " + time();
  return result;
};

const coverImgErrorHandler = (
  _event: React.SyntheticEvent<HTMLImageElement, Event>
) => {
  _event.currentTarget.src = defaultCoverImg;
};

type NameCardItemProps = {
  imageUrl: string;
  cardId: number;
  cardTitle: string;
  updatedAt: string;
  createdAt: string;
  editHandler: (cardId: number) => void;
  deleteHandler: (cardId: number) => void;
  shareHandler: (cardId: string) => void;
};

const NameCardItem = ({
  imageUrl,
  cardId,
  cardTitle,
  updatedAt,
  createdAt,
  editHandler,
  deleteHandler,
  shareHandler,
}: NameCardItemProps) => {
  return (
    <div className={classes.cardContainer} key={`card-${cardId}`}>
      <div className={classes.coverImg}>
        <img
          onError={(e) => coverImgErrorHandler(e)}
          src={imageUrl}
          alt="CoverImg"
        />
        <div className={classes.buttonBar}>
          <div
            className={classes.buttonDefault}
            onClick={() => shareHandler(cardId.toString())}
          >
            <img src={shareLogo} alt="share" />
          </div>

          <div
            className={classes.buttonDefault}
            onClick={() => editHandler(cardId)}
          >
            <img src={editLogo} alt="edit" />
          </div>

          <div
            className={classes.buttonDelete}
            onClick={() => deleteHandler(cardId)}
          >
            <img src={deleteLogo} alt="delete" />
          </div>
        </div>
      </div>
      <div className={classes.cardTitle}>{`${cardTitle}`}</div>
      <div className={classes.changelog}>
        <div className={classes.changelogCol}>
          <div>上次編輯</div>
          <div className={classes.time}>{timeFormat(updatedAt)}</div>
        </div>
        <div className={classes.changelogCol}>
          <div>建立時間</div>
          <div className={classes.time}>{timeFormat(createdAt)}</div>
        </div>
      </div>
    </div>
  );
};

export default NameCardItem;
