import { useEffect, useState } from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

import { addNameCards, updateNameCards } from "../../../api";

import { useNameCardFromValidate } from "../../../context/NameCardFromValidate";
import { INameCardType } from "../../../interface/nameCard";
import classes from "../Footer.module.sass";
import {
  useShareFlexMsg,
  IFlexMsgContent,
} from "../../../hooks/useShareFlexMsg";

import { message } from "antd";

import {
  useAppStateStore,
  nameCardV1DataType,
  nameCardV1SettingType,
} from "../../../store/appState";
import { isAllValidationsPassed } from "../../../utils/isAllValidationsPassed";

const NameCardsListFooter = () => {
  const navigate = useNavigate();
  const { hasValidateButton, hasValidateImageUrl } = useNameCardFromValidate();
  const [isCanSave, setIsCanSave] = useState<boolean>(true);
  const { sendFlexMsgFromCurrentContent } = useShareFlexMsg();
  const { NAMECARD_DATA, USER } = useAppStateStore();
  const {
    nameCardData,
    setNameCardV1Data,
    setNameCardV1Setting,
    initNameCardV1Data,
    initNameCardV1Setting,
    initCurrentCategory,
  } = NAMECARD_DATA;
  const { nameCardV1Data, nameCardV1Setting, currentCategory } = nameCardData;
  const { accessToken } = USER.userState;

  const sendMsgContent: { _flexMsgContent: IFlexMsgContent; altText: string } =
    {
      _flexMsgContent: {
        flexMsgType: { flexMsgType: "V1.0FlexMsg" },
        currentContent: {
          flexMsg: nameCardV1Data!,
          flexMsgSetting: nameCardV1Setting,
        },
      },
      altText: nameCardV1Setting.altText,
    };

  useEffect(() => {
    let _isCanSave = true;
    _isCanSave =
      hasValidateButton.every((item) => item !== false) &&
      hasValidateImageUrl.every((item) => item !== false);
    setIsCanSave(_isCanSave);
  }, [hasValidateButton, hasValidateImageUrl]);

  /**
   * 發建立數位名片 api
   * @param _accessToken //會員專屬的 accessToken
   */
  const AddNameCard = async (_accessToken: string) => {
    const _cardContent = { nameCardV1Setting, nameCardV1Data };

    const getImgUrls = (content: nameCardV1DataType[] | null) => {
      const imageUrls: string[] = [];
      if (content) {
        content.forEach((message) => {
          message.imageUrl.forEach((image) => {
            if (image.imageUrl.includes("brand/name_cards/")) {
              imageUrls.push(image.imageUrl);
            }
          });
        });
      }
      return imageUrls;
    };

    const nameCardContent: INameCardType = {
      card_type: nameCardV1Data && nameCardV1Data.length > 1 ? 2 : 1,
      card_title: nameCardV1Setting.cardTitle,
      message_text: nameCardV1Setting.altText,
      card_content: JSON.stringify(_cardContent),
      image_urls: getImgUrls(nameCardV1Data),
      user_category_id: currentCategory.user_category_id,
      category_name: currentCategory.category_name,
      user_folder_id: currentCategory.user_folder_id,
      folder_name: currentCategory.folder_name,
    };
    try {
      if (_accessToken) {
        const { data: res } = await addNameCards(_accessToken, nameCardContent);
        if (res) {
          const { data } = res;
          const hasShareNameCardButton =
            nameCardContent.card_content.indexOf("分享名片") !== -1;
          const nameCardHash = data.data.hash_id;
          console.log("nameCardHash", nameCardHash);
          const cardID = data.data.id;

          console.log("hasShareNameCardButton", hasShareNameCardButton);
          if (hasShareNameCardButton) {
            const newNameCardContent: INameCardType = JSON.parse(
              JSON.stringify(nameCardContent)
            );
            const { card_content } = newNameCardContent;
            const newCardContent: {
              nameCardV1Setting: nameCardV1SettingType;
              nameCardV1Data: nameCardV1DataType[];
            } = JSON.parse(card_content);
            const result: nameCardV1DataType[] =
              newCardContent.nameCardV1Data.map((_flexMsg, index) => {
                return {
                  ..._flexMsg,
                  button: _flexMsg.button.map((_button) => {
                    if (
                      _button.type === "shareNameCardButton" &&
                      !_button.content.buttonUrl.includes(nameCardHash)
                    ) {
                      _button.content.buttonUrl += nameCardHash;
                      return _button;
                    } else {
                      return _button;
                    }
                  }),
                };
              });

            console.log("加上分享自己按鈕的 flex message :", result);
            const updateContent = {
              nameCardV1Setting: nameCardV1Setting,
              nameCardV1Data: result,
            };
            const updateNameCardData: INameCardType = {
              card_type: result && result.length > 1 ? 2 : 1,
              card_title: nameCardV1Setting.cardTitle || "電子名片標題",
              message_text: nameCardV1Setting.altText || "電子名片訊息",
              card_content: JSON.stringify(updateContent),
              image_urls: getImgUrls(result),
              user_category_id: currentCategory.user_category_id,
              category_name: currentCategory.category_name,
              user_folder_id: currentCategory.user_folder_id,
              folder_name: currentCategory.folder_name,
            };
            const { data: updateNameCardRes } = await updateNameCards(
              _accessToken,
              cardID,
              updateNameCardData
            );
            console.log(updateNameCardRes);
          }
        }
      }
    } catch (error) {
      console.log("建立新名片錯誤", error);
    }
  };

  const validateNameCard = (): boolean => {
    const errors: JSX.Element[] = [];

    // 檢查按鈕文字是否為空值
    const hasButtonLabelEmpty = nameCardV1Data?.some((item) =>
      item.button.some((button) => {
        return !button.content.buttonText.trim();
      })
    );

    if (!nameCardV1Setting.cardTitle.trim()) {
      errors.push(<div>電子名片名稱 尚未填寫</div>);
    }
    if (!nameCardV1Setting.altText.trim()) {
      errors.push(<div>LINE 訊息通知文字 尚未填寫</div>);
    }
    if (!isAllValidationsPassed(hasValidateButton)) {
      errors.push(
        <div>
          部分電子名片按鈕（例如：電話、網站連結）尚未通過驗證，請檢查並修正無效的按鈕。
        </div>
      );
    }
    if (hasButtonLabelEmpty) {
      errors.push(<div>按鈕文字不能為空，請檢查並修正</div>);
    }

    if (errors.length > 0) {
      message.error(<div>{errors}</div>);
      return false;
    }
    return true;
  };
  return (
    <div className={`${classes.buttonItem}  ${classes.navBar}`}>
      <div className={`${classes.buttonItem} ${classes.cancelButton}`}>
        <Button
          type="primary"
          className={`${classes.button} ${classes.buttonGreyBg}`}
          onClick={() => {
            navigate(`/`);
            initNameCardV1Data();
            initNameCardV1Setting();
            initCurrentCategory();
          }}
        >
          <div className={classes.buttonWord}>取消</div>
        </Button>
      </div>
      <div className={classes.buttonItem}>
        <Button
          type="primary"
          className={classes.button}
          disabled={!isCanSave}
          onClick={() => {
            if (!validateNameCard()) {
              return;
            }

            accessToken && AddNameCard(accessToken);
            navigate(`/`);
          }}
        >
          <div className={classes.buttonWord}>儲存</div>
        </Button>
      </div>
      <div className={classes.buttonItem}>
        <Button
          type="primary"
          className={classes.send}
          onClick={() => {
            if (!nameCardV1Data) return;
            if (!validateNameCard()) {
              return;
            }
            sendFlexMsgFromCurrentContent(
              sendMsgContent._flexMsgContent,
              sendMsgContent.altText
            );
          }}
        >
          <div className={classes.buttonWord}>發送</div>
        </Button>
      </div>
    </div>
  );
};

export default NameCardsListFooter;
