import { useEffect, useState, Fragment } from "react";
import { Modal, Button } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { format } from "date-fns";
import classes from "./NameCardsList.module.sass";
import { useShareFlexMsg } from "../../../hooks/useShareFlexMsg";
import { useAppStateStore } from "../../../store/appState";
import {
  queryNameCardList,
  nameCardType,
  deleteNameCard,
} from "../../../api/index";
import {
  queryUserCategories,
  UserCategoriesDataType,
} from "../../../api/category";
import {
  getUserFolderByCategoryId,
  UserFolderDataType,
} from "../../../api/folder";
import Loading from "../../Loading";
import NameCardFilters from "./NameCardFilters";
import defaultCoverImg from "../../../image/default-cover.svg";
import NameCardItem from "./NameCardItem";
type NameCardQueryStateType = {
  categoryId?: string;
  folderId?: string;
  keyword?: string;
};

type LocationStateType = {
  queryState?: NameCardQueryStateType;
};

const NameCardsList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { shareNameCard, error } = useShareFlexMsg();
  const [nameCards, setNameCards] = useState<nameCardType[]>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { APP, BRAND, USER, PAGEMODE, NAMECARD_DATA } = useAppStateStore(); // 24.11.01 SCH ★
  const { appState, setAppState, currentConfig } = APP;
  const { brandState } = BRAND; // 24.11.01 SCH ★
  const { userState } = USER;
  const { accessToken } = userState;
  const { setCurrentPageModeState } = PAGEMODE;
  const {
    initNameCardV1Data,
    initNameCardV1Setting,
    initNameCardV3Data,
    initNameCardV3Setting,
    initCurrentCategory,
  } = NAMECARD_DATA;
  const [errorMsg, setErrorMsg] = useState<{
    errMsg: string;
    error: boolean;
  }>({ errMsg: "", error: false });
  const [isDelteModalOpen, setIsDelteModalOpen] = useState<{
    show: boolean;
    cardId: number | null;
  }>({
    show: false,
    cardId: null,
  });

  // 初始化 queryState 從 location.state 中 取得
  const { queryState: initialQueryState } =
    (location.state as LocationStateType) || {};

  const { role, eagle, editable, trial_due_at } = userState; // 24.10.31 SCH
  const [trialDueAt, setTrialDueAt] = useState(""); // 24.10.31 SCH ★
  const [banLoign, setBanLoign] = useState(false); // 24.10.31 SCH ★
  const [isEditBanModalOpen, setIsEditBanModalOpen] = useState(false); // 24.11.02 SCH ★
  const [isHintModalOpen, setIsHintModalOpen] = useState(false); // 24.11.21 SCH ★
  const [queryState, setQueryState] = useState<NameCardQueryStateType>({});
  const [tempQueryState, setTempQueryState] = useState<NameCardQueryStateType>(
    initialQueryState || {}
  );
  const [categoryList, setCategoryList] = useState<UserCategoriesDataType[]>(
    []
  );
  const [folderList, setFolderList] = useState<UserFolderDataType[]>([]);
  /**
   * 查詢名片類別
   */
  const queryCategoryList = async () => {
    try {
      if (accessToken) {
        const { data: res } = await queryUserCategories(accessToken);
        res?.data && setCategoryList(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * 查詢資料夾 by categoryId 名片類別ID
   * @param categoryId 名片類別ID
   */
  const queryFolderList = async (categoryId: number) => {
    try {
      if (accessToken) {
        const { data: res } = await getUserFolderByCategoryId(
          accessToken,
          categoryId
        );
        res?.data && setFolderList(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //有line_id就用 line_id 要名片資料, 沒有 line_id 就使用 mobile_number 來要名片列表資料
  const getUerNameCards = async () => {
    setAppState({ isLoading: true });
    console.log("queryState", queryState);

    try {
      if (accessToken && userState) {
        const { line_id, mobile_number } = userState;
        if (mobile_number) {
          const { data: res } = await queryNameCardList(
            accessToken,
            mobile_number,
            line_id,
            queryState.categoryId,
            queryState.folderId,
            queryState.keyword
          );
          if (res) {
            const { data } = res;
            console.log("name_cards", data);
            setNameCards(data);
          }
        }
      }
    } catch (error) {
      console.log(error);
      setErrorMsg({ errMsg: JSON.stringify(error), error: true });
      setAppState({ isLoading: false });
    }
  };

  const DeleteNameCard = async (_card_id: number) => {
    try {
      if (accessToken) {
        setAppState({ isLoading: true });

        console.log("name_cards", `刪除${_card_id}`);
        await deleteNameCard(accessToken, _card_id);
      }
    } catch (error) {
      setErrorMsg({ errMsg: JSON.stringify(error), error: true });
      console.log(error);
    }
  };

  const getCoverImg = (_image_urls: string[]) => {
    if (!_image_urls || _image_urls?.length === 0) return defaultCoverImg;
    return `${currentConfig.REACT_APP_IMAGEBASEURL}${_image_urls[0]}`;
  };

  const handleCategoryChange = (categoryId: string | undefined) => {
    const newTempQueryState = categoryId ? { categoryId } : {};
    console.log("newTempQueryState", newTempQueryState);
    setTempQueryState(newTempQueryState);
  };

  const handleFolderChange = (folderId: string | undefined) => {
    const newTempQueryState = {
      ...tempQueryState,
      folderId,
    };
    if (!folderId) {
      delete newTempQueryState.folderId;
    }
    setTempQueryState(newTempQueryState);
  };

  const handleKeywordChange = (keyword: string) => {
    const newTempQueryState = {
      ...tempQueryState,
      keyword: keyword.trim() || undefined,
    };
    if (!newTempQueryState.keyword) {
      delete newTempQueryState.keyword;
    }
    setTempQueryState(newTempQueryState);
  };

  const handleConfirmFilter = () => {
    setQueryState(tempQueryState);
    navigate(".", { state: { queryState: tempQueryState }, replace: true });
  };

  const handleClearFilters = () => {
    const emptyState = {};
    setQueryState(emptyState);
    setTempQueryState(emptyState);
    setFolderList([]);
    navigate(".", { state: { queryState: emptyState }, replace: true });
  };

  const hasActiveFilters = Boolean(
    queryState.categoryId || queryState.folderId || queryState.keyword
  );

  /**
   * 編輯名片的處理
   * @param _cardId 名片ID
   */
  const handleEdit = (_cardId: number) => {
    if (role >= 2 || editable || eagle) {
      navigate(`/update/${_cardId}`);
    } else {
      setIsEditBanModalOpen(true);
    }
  };

  /**
   * 刪除名片的處理
   * @param _cardId 名片ID
   */
  const handleDelete = (_cardId: number) => {
    setIsDelteModalOpen({
      show: true,
      cardId: _cardId,
    });
  };

  // 錯誤提示的 Modal 確定
  const handleOk = () => {
    setIsModalOpen(false);
  };

  // 錯誤提示的 Modal 關閉
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // 刪除名片的 Modal 確定
  const okDelteModalHandle = () => {
    console.log(isDelteModalOpen.cardId);
    if (isDelteModalOpen.cardId) {
      DeleteNameCard(isDelteModalOpen.cardId);
    }
    setIsDelteModalOpen({
      show: false,
      cardId: null,
    });
  };

  // 刪除名片的 Modal 關閉
  const cancelDelteModalHandle = () => {
    setIsDelteModalOpen({
      show: false,
      cardId: null,
    });
  };

  // 提示免費試用期限的 Modal 確定
  const EditBanHandleOk = () => {
    setIsEditBanModalOpen(false);
  };

  // 提示免費試用期限的 Modal 關閉
  const EditBanHandleCancel = () => {
    setIsEditBanModalOpen(false);
  };

  // 新手提示的 Modal 確定
  const HintHandleOk = () => {
    setIsHintModalOpen(false);
  };

  // 新手提示的 Modal 關閉
  const HintHandleCancel = () => {
    setIsHintModalOpen(false);
  };

  // 檢查免費試用期限
  useEffect(() => {
    if (accessToken && userState) {
      const trialDueAt = trial_due_at?.toString();
      if (trialDueAt) setTrialDueAt(trialDueAt); // 24.10.31 SCH ★
      const now_date = format(new Date(), "yyyy-MM-dd");
      if (role < 2 && trialDueAt !== undefined) {
        if (brandState === undefined) {
          // 沒抓到資料！
          console.log("brandState", brandState);
          if (now_date > trialDueAt && !eagle) {
            setBanLoign(true); // 24.10.31 SCH ★
          }
        } else {
          // 有抓到資料！ // 24.11.01 SCH ★
          console.log("brandState", brandState);
          if (brandState.use_trial_due_at && now_date > trialDueAt && !eagle) {
            if (!brandState.use_login_log_count) {
              setBanLoign(true); // 24.11.01 SCH ★
            } else {
              if (brandState.login_log_count > brandState.trial_login_times) {
                setBanLoign(true); // 24.11.01 SCH ★
              }
            }
          }
        }
      }
    }

    if (accessToken && userState) {
      setTimeout(() => {
        getUerNameCards();
        queryCategoryList();
        setAppState({ isLoading: false });
      }, 500);
    }
  }, [
    accessToken,
    userState,
    appState.isLoading,
    brandState,
    eagle,
    role,
    trial_due_at,
  ]); // 24.11.01 SCH ★

  useEffect(() => {
    // 如果 tempQueryState 不存在，則不執行
    if (!tempQueryState) return;
    // 如果 tempQueryState.categoryId 不存在，則不執行
    if (!tempQueryState.categoryId) return;
    // 將 tempQueryState.categoryId 轉換為數字
    const categoryId = Number(tempQueryState.categoryId);
    // 確認 categoryId 是數字
    if (typeof categoryId !== "number" || isNaN(categoryId)) return;
    // query 資料夾列表
    queryFolderList(categoryId);
  }, [tempQueryState, tempQueryState.categoryId, accessToken]);

  // 初始化 nameCard V1 & V3 的狀態
  // 設定現在的頁面是 nameCardsList
  useEffect(() => {
    setCurrentPageModeState("nameCardsList");
    // 初始化 nameCard V1 的狀態
    initNameCardV1Data();
    // 初始化 nameCard V1 的設定
    initNameCardV1Setting();
    // 初始化 nameCard V3 的狀態
    initNameCardV3Data();
    // 初始化 nameCard V3 的設定
    initNameCardV3Setting();
    // 初始化 currentCategory 的狀態
    initCurrentCategory();
  }, []);

  // error 狀態是 share 名片時的錯誤
  // 當 分享名片 發生錯誤時，開啟 錯誤提示的 Modal
  useEffect(() => {
    error && setIsModalOpen(true);
  }, [error]);

  // 初始化 queryState 從 location.state 中 取得
  useEffect(() => {
    if (initialQueryState) {
      setQueryState(initialQueryState);
      setTempQueryState(initialQueryState);
    }
  }, [initialQueryState]);

  // 如果 userState.mobile_number 是 null，則開啟新手提示的 Modal
  useEffect(() => {
    if (userState.mobile_number === null) {
      setIsHintModalOpen(true); // 24.12.04 SCH ★
    }
  }, [userState]);

  // 捕獲 queryState 的狀態變化，狀態改變時，查詢名片列表
  useEffect(() => {
    getUerNameCards();
  }, [queryState]);

  if (errorMsg.error === true) return <div>{errorMsg.errMsg}</div>;
  return (
    <Fragment>
      <NameCardFilters
        categories={categoryList}
        folders={folderList}
        selectedCategory={tempQueryState.categoryId}
        selectedFolder={tempQueryState.folderId}
        keyword={tempQueryState.keyword}
        onKeywordChange={handleKeywordChange}
        onCategoryChange={handleCategoryChange}
        onFolderChange={handleFolderChange}
        onConfirmFilter={handleConfirmFilter}
        onClearFilters={handleClearFilters}
        hasActiveFilters={hasActiveFilters}
      />
      {appState.isLoading ? (
        <Loading />
      ) : (
        <div className={classes.container}>
          {!appState.isLoading &&
          nameCards !== undefined &&
          nameCards.length === 0 ? (
            <h3>
              沒有找到任何電子名片，您可以嘗試調整搜尋條件或新增電子名片。
            </h3>
          ) : banLoign ? (
            <h3>您已逾免費試用期限（{`${trialDueAt}`}）喔！</h3> // 24.10.31 SCH ★
          ) : (
            <>
              {nameCards &&
                nameCards.map((item) => (
                  <NameCardItem
                    key={item.card_id}
                    imageUrl={getCoverImg(item.image_urls)}
                    cardId={item.card_id}
                    cardTitle={item.card_title}
                    updatedAt={item.updated_at}
                    createdAt={item.created_at}
                    editHandler={handleEdit}
                    deleteHandler={handleDelete}
                    shareHandler={shareNameCard}
                  />
                ))}
            </>
          )}
        </div>
      )}

      <Modal
        title="提醒"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>名片無法發送，請檢查名片內容有無不符合規定地方</p>
      </Modal>

      <Modal
        title="提醒"
        open={isDelteModalOpen.show}
        onCancel={cancelDelteModalHandle}
        footer={false}
      >
        <div className={classes.deleteModal}>
          <p>是否確定要刪除此電子名片</p>
          <div>
            <Button
              className={classes.button}
              type="primary"
              ghost
              onClick={okDelteModalHandle}
            >
              <div className={classes.buttonWord}>確定刪除</div>
            </Button>
            <Button
              className={classes.button}
              type="primary"
              ghost
              onClick={cancelDelteModalHandle}
            >
              <div>取消</div>
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        title="編輯權益限制提醒"
        open={isEditBanModalOpen}
        onOk={EditBanHandleOk}
        onCancel={EditBanHandleCancel}
      >
        <p>
          無法編輯名片，您現持有張數已滿免費試用數（
          {`${brandState.trial_name_cards}`}）喔！
        </p>
      </Modal>

      <Modal
        title="新手提醒"
        open={isHintModalOpen}
        onOk={HintHandleOk}
        onCancel={HintHandleCancel}
      >
        <p>
          歡迎您加入數位名片設計行列，請您先觸按右上角「三條線」按鈕，進入「個人資料」頁面輸入基本資料，
          以利後續您的數位名片設計製作並分享，謝謝！
        </p>
      </Modal>
    </Fragment>
  );
};
export default NameCardsList;
