import { Result, Button } from "antd";
import { useNavigate } from "react-router-dom";
import classes from "./ResultPage.module.sass";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <Result
      status="404"
      subTitle="抱歉，您訪問的頁面不存在。"
      extra={
        <Button
          onClick={() => navigate("/")}
          type="primary"
          className={classes.button}
        >
          返回首頁
        </Button>
      }
    />
  );
};

export default NotFound;
